import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from "history";

//#region user
import { useSelector } from "react-redux";
//#endregion

import PrivateRoutes from "./PrivateRoutes";
import SuperAdminRoute from "./SuperAdminRoute";
//#region Admin
import Login from "../component/admin/auth/Login";
import ForgotPassword from "../component/admin/auth/ForgotPassword";
import ResetPassword from "../component/admin/auth/ResetPassword";
import Layout from "../container/admin/pages/layout/Layout";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import InvoiceAdd from "../container/admin/pages/invoice/Add";
import SingleInvoice from "../container/admin/pages/invoice/SingleInvoice";
import ParticularModel from "../container/admin/pages/invoice/ParticularModel";
import BargeList from "../container/admin/pages/barge/List";
import BargeAdd from "../container/admin/pages/barge/Add";
import ViewCraft from "../container/admin/pages/barge/View.js";
import PortList from "../container/admin/pages/port/List";
import PortAdd from "../container/admin/pages/port/Add";
import Profile from "../container/admin/pages/profile/Profile";
import Orderadd from "../container/admin/pages/orderadd/OrderAdd";
import EditBunkerTiming from "../container/admin/pages/orderadd/EditBunkerTiming";
// import Supplyorder from "../container/admin/pages/orderadd/SupplyOrder";
import TankerTrucks from "../container/admin/pages/tankertrucks/TankerTrucks";
import ChangePassword from "../container/admin/pages/changepassword/ChangePassword";
import SelectCompny from "../container/admin/pages/selectcompny/SelectCompny";
import { SelectModal } from "../container/admin/pages/selectcompny/SelectModal";
// import CompanyDash from "../container/admin/pages/companyDash/CompanyDash";
import DashboardTab from "../container/admin/pages/companyDash/DashboardTab.js";
import Invoice from "../container/admin/pages/invoice/Invoice";
import DashboardLayout from "../container/admin/pages/dashboard/DashboardLayout";
// import PurchaseList from "../container/admin/pages/purchase/List";
// import TaxableModule from "../container/admin/pages/Taxable/Add";
import Rate from "../container/admin/pages/Rate/Rate";
import BankTab from "../container/admin/pages/Bank/BankTab";
import PartyList from "../container/admin/pages/Party/List";
import PurchaseTab from "../container/admin/pages/purchase/PurchaseTab";
import InquiryAdd from "../container/admin/pages/enquiry/Add";
import SingleOrder from "../container/admin/pages/orderadd/SingleOrder";
// import Users from "../container/admin/pages/users/List";
import UserList from "../container/admin/pages/users/List";
import UsersAdd from "../container/admin/pages/users/Add";
import SingleUser from "../container/admin/pages/users/SingleUser";
import EnquiryTab from "../container/admin/pages/enquiry/EnquiryTab";
import PageNotFound from "../component/common/PageNotFound";
import InvoiceDetails from "../container/admin/pages/companyDash/DashboardCharts/InvoiceDetails";
import ExpenseList from "../container/admin/pages/expenseList/List";
import Expense from "../container/admin/pages/expense/List";
import ExpenseDetail from "../container/admin/pages/expenseList/ExpenseDetail.js";
import OrderTab from "../container/admin/pages/orderadd/OrderTab";
import TraderList from "../container/admin/pages/traderMaster/List";
import AddTrader from "../container/admin/pages/traderMaster/Add";
import TraderEnqList from "../container/admin/pages/trader/TraderEnqList";
import TraderEnquiryAdd from "../container/admin/pages/trader/TraderEnquiryAdd";
import TankerList from "../container/admin/pages/tanker/List";
import TankerView from "../container/admin/pages/tanker/View.js";
import TankerAdd from "../container/admin/pages/tanker/Add";
import RoleMaster from "../container/admin/pages/roleMaster/List";
import AddRoleMaster from "../container/admin/pages/roleMaster/Add";
import BunkerDailyExpenseList from "../container/admin/pages/pettyCase/List";
import AddEmployee from "../container/admin/pages/pettyCase/Add";
import SingleEmployee from "../container/admin/pages/pettyCase/SingleEmployee";
import UserDashboard from "../container/admin/pages/companyDash/UserDashboard";
import NotificationList from "../container/admin/pages/notifications/List";
import SalesMasterList from "../container/admin/pages/salesMaster/List.js";
import AddSalesMaster from "../container/admin/pages/salesMaster/Add.js";
import GSTRList from "../container/admin/pages/report/GSTRList";
import GstrTwo from "../container/admin/pages/report/GstrTwo.js";
import TDSList from "../container/admin/pages/report/TDSList";
import InvoiceTab from "../container/admin/pages/invoice/InvoiceTab.js";
import SalesTab from "../container/admin/pages/salesMaster/SalesTab.js";
import SalesAddEnquiry from "../container/admin/pages/salesMaster/SalesAddEnquiry.js";
import ExpenseTab from "../container/admin/pages/expenseList/ExpenseTab.js";
import OtherExpenseDetail from "../container/admin/pages/expenseList/OtherExpenseDetail.js";
import TaskList from "../container/admin/pages/task/List.js";
import AddTask from "../container/admin/pages/task/Add.js";
import ViewTaskDetail from "../container/admin/pages/task/View.js";

import DirectoryList from "../container/admin/pages/directory/List.js";
import DirectoryAdd from "../container/admin/pages/directory/Add.js";
import TankerDriverView from "../container/admin/pages/tanker/driver/TankerDriverView.js";
import BreakDownList from "../container/admin/pages/breakdown/List.js";
import TankerTripsList from "../container/admin/pages/tanker/TankerTripsList.js";
import MaintenanceList from "../container/admin/pages/maintenance/List.js";
import MaintenanceView from "../container/admin/pages/maintenance/View.js";
import ActiveUserList from "../container/admin/pages/activeUsers/List.js";
import TankerDashboard from "../container/admin/pages/tanker/TankerDashboard.js";
import TripDashboard from "../container/admin/pages/tanker/TripDashboard.js";
import TankerDashboardView from "../container/admin/pages/tanker/TankerDashboardView.js";
import TankerTrips from "../container/admin/pages/tankerTrips/List.js";
import TugOrderTab from "../container/admin/pages/orderTug/TugOrderTab.js";
import TugOrderAdd from "../container/admin/pages/orderTug/TugOrderAdd.js";
import SingleTugOrder from "../container/admin/pages/orderTug/SingleTugOrder.js";
import EditOrderTugTiming from "../container/admin/pages/orderTug/EditOrderTugTiming.js";
import WaterSupplyTab from "../container/admin/pages/orderWaterSupply/WaterSupplyTab.js";
import WaterSupplyAdd from "../container/admin/pages/orderWaterSupply/WaterSupplyAdd.js";
import SingleWaterSupplyTab from "../container/admin/pages/orderWaterSupply/singleWaterSupply/SingleWaterSupplyTab.js";
import EditWaterSupplyTiming from "../container/admin/pages/orderWaterSupply/singleWaterSupply/EditWaterSupplyTiming.js";
import SlopeAndSludgeOrderTab from "../container/admin/pages/orderSlopeAndSludge/SlopeAndSludgeOrderTab.js";
import SlopeAndSludgeOrderAdd from "../container/admin/pages/orderSlopeAndSludge/SlopeAndSludgeOrderAdd.js";
import SingleSlopeAndSludgeTab from "../container/admin/pages/orderSlopeAndSludge/singleSlopeAndSludge/SingleSlopeAndSludgeTab.js";

import EditSlopeAndSludgeTiming from "../container/admin/pages/orderSlopeAndSludge/EditSlopeAndSludgeTiming.js";
import EditOrderPipeLine from "../container/admin/pages/orderadd/EditOrderPipeLine.js";
import TraderEnqTab from "../container/admin/pages/trader/TraderEnqTab.js";
import TankerTab from "../container/admin/pages/tanker/TankerTab.js";
import PurchaseInvoiceDetails from "../container/admin/pages/companyDash/DashboardCharts/PurchaseInvoiceDetails.js";
import PurchaseInvoiceDetailTab from "../container/admin/pages/companyDash/DashboardCharts/PurchaseInvoiceDetails/PurchaseInvoiceDetailTab.js";
import DueInvoiceDetails from "../container/admin/pages/companyDash/DashboardCharts/DueInvoiceDetails.js";
import CategoryWiseInvoiceDetails from "../container/admin/pages/companyDash/DashboardCharts/CategoryWiseInvoiceDetails.js";
import TopSalesPartyWise from "../container/admin/pages/companyDash/DashboardCharts/TopSalesPartyWise.js";
import WharfageList from "../container/admin/pages/wharfage/WharfageList.js";
import CraftPreventionList from "../container/admin/pages/craftPrevention/CraftPreventionList.js";
import AddCraftPrevention from "../container/admin/pages/craftPrevention/AddCraftPrevention.js";
import CraftMaintenanceTab from "../container/admin/pages/maintenance/CraftMaintenanceTab.js";
import ViewCraftPrevention from "../container/admin/pages/craftPrevention/ViewCraftPrevention.js";
import DocumentCenter from "../container/admin/pages/documentCenter/DocumentCenterList.js";
import DueDocumentList from "../container/admin/pages/companyDash/DashboardCharts/DueDocumentList.js";
import EditCurrentOrder from "../container/admin/pages/orderadd/EditCurrentOrder.js";
import LogsCraftPrevention from "../container/admin/pages/craftPrevention/LogsCraftPrevention.js";
import RemarkHistory from "../container/admin/pages/documentCenter/RemarkHistory.js";
import DocumentsHistory from "../container/admin/pages/barge/document/DocumentsHistory.js";
import Transfer from "../container/admin/pages/Trasfer/Transfer.js";
import NewCraftPreventionList from "../container/admin/pages/craftPrevention/NewCraftPreventionList.js";
import PreventiveSubHeadList from "../container/admin/pages/craftPrevention/subHead/PreventiveSubHeadList.js";
import AddPreventiveSubHead from "../container/admin/pages/craftPrevention/subHead/AddPreventiveSubHead.js";
import CraftWiseMaintenance from "../container/admin/pages/maintenance/CraftWiseMaintenance.js";
import TallyInvoiceComponent from "../container/admin/pages/tallyInvoice/TallyInvoiceComponent.js";
// import TallyInvoice from "../container/admin/pages/tallyInvoice/TallyInvoice.js";
import CompaniesList from "../container/admin/pages/companies/CompaniesList.js";
import AddCompanies from "../container/admin/pages/companies/AddCompanies.js";
import BankHistory from "../container/admin/pages/BankHistory/BankHistory.js";
import NewEditCurrentOrder from "../container/admin/pages/orderadd/NewEditCurrentOrder.js";
import { DatePickerProvider } from "../context/DatePickerContext.js";
import TallyCompanyWiseBankData from "../container/admin/pages/companies/TallyCompanyWiseBankData.js";
import PurchaseSelection from "../container/admin/pages/companies/PurchaseSelection.js";
import JournalSelection from "../container/admin/pages/companies/JournalSelection.js";
import TallyInvoiceDetail from "../container/admin/pages/tallyInvoice/TallyInvoiceDetail.js";
import BetaPurchaseTab from "../container/admin/pages/purchaseBeta/BetaPurchaseTab.js";
import HistoryOfShifTanker from "../container/admin/pages/orderadd/HistoryOfShiftTanker.js";
import SalesSelection from "../container/admin/pages/companies/SalesSelection.js";

const history = createBrowserHistory();
export default function Routes() {
  const login = useSelector((state) => state.AdminReducer.adminDetails);

  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        {/* <Route path="trader/enq/list/:id" element={<TraderEnqList />} /> */}
        <Route path="trader/enq/list/:id" element={<TraderEnqTab />} />
        <Route path="trader/enq/add/:id" element={<TraderEnquiryAdd />} />
        <Route path="sales/enq/list/:id" element={<SalesTab />} />
        <Route path="sales/enq/add/:id" element={<SalesAddEnquiry />} />

        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            {/* Barge pages */}
            <Route path="/admin" element={<DashboardLayout />}>
              <Route path="/admin/trip/dashboard" element={<TripDashboard />} />

              <Route element={<SuperAdminRoute login={login} />}>
                {/* <Route path="/admin/companyDashboard" element={<CompanyDash />} /> */}
                <Route
                  path="/admin/companyDashboard"
                  element={<DashboardTab />}
                />
                <Route
                  path="/admin/tankerDashboard"
                  element={<TankerDashboard />}
                />
                <Route
                  path="/admin/user-dashboard"
                  element={<UserDashboard />}
                />
                <Route
                  path="/admin/order/bunker-timing"
                  element={<EditBunkerTiming />}
                />
                <Route
                  path="/admin/order/pipeline-details"
                  element={<EditOrderPipeLine />}
                />
                <Route
                  path="/admin/tugsOrder/tug-timing"
                  element={<EditOrderTugTiming />}
                />
                <Route path="/admin/enquiry" element={<EnquiryTab />} />
                <Route path="/admin/enquiry/add" element={<InquiryAdd />} />
                <Route
                  path="/admin/enquiry/edit/:id"
                  element={<InquiryAdd />}
                />

                <Route path="/admin/expenseList" element={<ExpenseTab />} />
                <Route
                  path="/admin/notification"
                  element={<NotificationList />}
                />
                <Route
                  path="/admin/expenseList/view-barge-expense/:id"
                  element={<ExpenseDetail />}
                />
                <Route
                  path="/admin/expenseList/view-other-expense/:id"
                  element={<OtherExpenseDetail />}
                />
                <Route
                  path="/admin/bunker"
                  element={<BunkerDailyExpenseList />}
                />
                <Route path="/admin/wharfage" element={<WharfageList />} />
                <Route
                  path="/admin/bunker/employee/add"
                  element={<AddEmployee />}
                />
                <Route
                  path="/admin/bunker/employee/view/:id"
                  element={<SingleEmployee />}
                />

                <Route path="/admin/invoice" element={<InvoiceTab />} />
                <Route path="/admin/order" element={<OrderTab />} />

                <Route
                  path="/admin/order/view-order/:id"
                  element={<SingleOrder />}
                />
                <Route
                  path="/admin/order/view-order/shift-tanker-history/:id"
                  element={<HistoryOfShifTanker />}
                />
                <Route path="/admin/order/add" element={<Orderadd />} />
                {/* <Route
                  path="/admin/order/edit/:id"
                  element={<EditCurrentOrder />}
                /> */}
                <Route
                  path="/admin/order/edit/:id"
                  element={<NewEditCurrentOrder />}
                />

                <Route
                  path="/admin/tugsOrder/view-order/:id"
                  element={<SingleTugOrder />}
                />
                <Route path="/admin/tugsOrder" element={<TugOrderTab />} />
                <Route path="/admin/tugsOrder/add" element={<TugOrderAdd />} />
                <Route
                  path="/admin/slopeSludgeOrder"
                  element={<SlopeAndSludgeOrderTab />}
                />
                <Route
                  path="/admin/slopeSludgeOrder/add"
                  element={<SlopeAndSludgeOrderAdd />}
                />

                <Route path="/admin/craft" element={<BargeList />} />
                <Route path="/admin/craft/add-craft" element={<BargeAdd />} />
                <Route path="/admin/craft/edit-craft" element={<BargeAdd />} />
                <Route path="/admin/craft/view/:id" element={<ViewCraft />} />
                <Route path="/admin/port" element={<PortList />} />
                <Route path="/admin/port/add-port" element={<PortAdd />} />
                <Route path="/admin/port/edit-port" element={<PortAdd />} />

                <Route path="/admin/companies" element={<CompaniesList />} />
                <Route
                  path="/admin/companies/purchase-selection/:id"
                  element={<PurchaseSelection />}
                />
                <Route
                  path="/admin/companies/journal-selection/:id"
                  element={<JournalSelection />}
                />
                <Route
                  path="/admin/companies/sales-selection/:id"
                  element={<SalesSelection />}
                />
                <Route
                  path="/admin/companies/:id"
                  element={<TallyCompanyWiseBankData />}
                />
                <Route path="/admin/companies/add" element={<AddCompanies />} />

                <Route
                  path="/admin/craft/document-history/:id"
                  element={<DocumentsHistory />}
                />

                <Route
                  path="/admin/craftPreventive"
                  element={<NewCraftPreventionList />}
                />
                <Route
                  path="/admin/craftPreventive/old-view"
                  element={<CraftPreventionList />}
                />

                <Route
                  path="/admin/craftPreventive/add-craft-preventive"
                  element={<AddCraftPrevention />}
                />
                <Route
                  path="/admin/craftPreventive/edit-craft-preventive"
                  element={<AddCraftPrevention />}
                />
                <Route
                  path="/admin/craftPreventive/view/:id"
                  element={<ViewCraftPrevention />}
                />

                <Route
                  path="/admin/craftPreventive/preventive-logs/:id"
                  element={<LogsCraftPrevention />}
                />
                {/* document center */}
                <Route
                  path="/admin/documentCenter"
                  element={<DocumentCenter />}
                />

                <Route
                  path="/admin/documentCenter/remark-history/:id"
                  element={<RemarkHistory />}
                />

                {/* Water Supply Order */}
                <Route path="/admin/waterSupply" element={<WaterSupplyTab />} />
                <Route
                  path="/admin/waterSupply/add"
                  element={<WaterSupplyAdd />}
                />
                <Route
                  path="/admin/waterSupply/edit"
                  element={<WaterSupplyAdd />}
                />
                <Route
                  path="/admin/waterSupply/view-order/:id"
                  element={<SingleWaterSupplyTab />}
                />
                <Route
                  path="/admin/waterSupply/edit-timing"
                  element={<EditWaterSupplyTiming />}
                />
                <Route
                  path="/admin/slopeSludgeOrder/view-order/:id"
                  element={<SingleSlopeAndSludgeTab />}
                />
                <Route
                  path="/admin/slopeSludgeOrder/edit-timing"
                  element={<EditSlopeAndSludgeTiming />}
                />

                {/* invoice pages */}
                <Route path="/admin/invoice" element={<Invoice />} />
                <Route
                  path="/admin/invoice/view-invoice/:id"
                  element={<SingleInvoice />}
                />
                <Route
                  path="/admin/invoice/particulars"
                  element={<ParticularModel />}
                />
                <Route
                  path="/admin/invoice/add-invoice"
                  element={<InvoiceAdd />}
                />
                {/* { Telly invoice} */}

                <Route
                  path="/admin/tallyInvoice"
                  element={<TallyInvoiceComponent />}
                />
                <Route
                  path="/admin/tallyInvoice/details/:id"
                  element={<TallyInvoiceDetail />}
                />

                <Route path="/admin/rate" element={<Rate />} />
                <Route path="/admin/purchase" element={<PurchaseTab />} />
                <Route
                  path="/admin/purchaseBeta"
                  element={<BetaPurchaseTab />}
                />
                <Route path="/admin/banks" element={<BankTab />} />
                <Route path="/admin/bankHistory" element={<BankHistory />} />
                {/* <Route path="/admin/taxable-module" element={<TaxableModule />} /> */}

                <Route path="/admin/breakdowns" element={<BreakDownList />} />

                <Route path="/admin/tankertrips" element={<TankerTrips />} />

                <Route
                  path="/admin/maintenances"
                  element={<CraftMaintenanceTab />}
                />

                <Route
                  path="/admin/maintenances/craft"
                  element={<CraftWiseMaintenance />}
                />

                <Route
                  path="/admin/maintenances/view/:id"
                  element={<MaintenanceView />}
                />

                <Route path="/admin/gstr" element={<GSTRList />} />
                <Route path="/admin/gstr2" element={<GstrTwo />} />
                <Route path="/admin/tds" element={<TDSList />} />

                <Route path="/admin/party" element={<PartyList />} />

                <Route path="/admin/users" element={<UserList />} />
                <Route path="/admin/users/add" element={<UsersAdd />} />
                <Route
                  path="/admin/users/view-users/:id"
                  element={<SingleUser />}
                />

                <Route path="/admin/activeUsers" element={<ActiveUserList />} />

                <Route path="/admin/directory" element={<DirectoryList />} />
                <Route path="/admin/directory/add" element={<DirectoryAdd />} />

                <Route path="/admin/expense" element={<Expense />} />
                <Route path="/admin/traders" element={<TraderList />} />
                <Route path="/admin/traders/add" element={<AddTrader />} />

                <Route
                  path="/admin/preventiveSubhead"
                  element={<PreventiveSubHeadList />}
                />
                <Route
                  path="/admin/preventiveSubhead/add"
                  element={<AddPreventiveSubHead />}
                />

                {/* <Route path="/admin/tankers" element={<TankerList />} /> */}
                <Route path="/admin/tankers" element={<TankerTab />} />
                <Route
                  path="/admin/tankers/view/:id"
                  element={<TankerView />}
                />
                <Route path="/admin/tankers/add" element={<TankerAdd />} />
                <Route
                  path="/admin/tankers/dashboard/view/:id"
                  element={<TankerDashboardView />}
                />
                <Route
                  path="/admin/tankers/driver/view"
                  element={<TankerDriverView />}
                />
                <Route
                  path="/admin/tankers/trips/:id"
                  element={<TankerTripsList />}
                />

                <Route path="/admin/tasks" element={<TaskList />} />
                <Route path="/admin/tasks/add" element={<AddTask />} />
                <Route path="/admin/tasks/edit/:id" element={<AddTask />} />
                <Route
                  path="/admin/tasks/view/:id"
                  element={<ViewTaskDetail />}
                />

                <Route
                  path="/admin/salesMaster"
                  element={<SalesMasterList />}
                />
                <Route
                  path="/admin/salesMaster/add"
                  element={<AddSalesMaster />}
                />

                <Route path="/admin/roles" element={<RoleMaster />} />
                <Route path="/admin/roles/add" element={<AddRoleMaster />} />
              </Route>
              <Route
                path="/admin/invoice/invoice-details"
                element={<InvoiceDetails />}
              />
              <Route
                path="/admin/category-wise-invoice-details"
                element={<CategoryWiseInvoiceDetails />}
              />
              <Route
                path="/admin/top-sales-party-wise"
                element={<TopSalesPartyWise />}
              />
              <Route
                path="/admin/invoice/due-invoice-details"
                element={<DueInvoiceDetails />}
              />
              <Route
                path="/admin/invoice/purchase-invoice-details"
                element={<PurchaseInvoiceDetailTab />}
              />

              <Route
                path="/admin/document/due-documents"
                element={<DueDocumentList />}
              />

              {/* Trasfer or Trashippment */}
              <Route path="/admin/transfer" element={<Transfer />} />

              <Route
                path="/admin/changepassword"
                element={<ChangePassword />}
              />
              <Route path="/admin/profile" element={<Profile />} />
            </Route>

            <Route path="/tankertrucks" element={<TankerTrucks />} />
            <Route path="/admin/selectcompany" element={<SelectCompny />} />
            <Route path="/admin/selectmodal" element={<SelectModal />} />
          </Route>
        </Route>

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routess>
    </BrowserRouter>
  );
}
