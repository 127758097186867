import Index from "../../../../Index";
import React, { useState, useEffect } from "react";
import APIService from "../../../../../redux/APIService";
import { createSearchParams } from "react-router-dom";
import { getDashboardBargeTallyWise } from "../../../../../redux/admin/action";
const TreeMap = (props) => {
  const dispatch = Index.useDispatch();
  const { financialYear, days } = props;
  const { activeCompany } = Index.useSelector((state) => state.AdminReducer);
  const [treeData, setTreeData] = useState([]);
  const [dateRange, setDateRange] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function getLastDayOfMonth(year, month) {
    // Create a Date object with the next month's first day
    var nextMonthFirstDay = new Date(year, month, 1);
    // Subtract one day from the next month's first day
    var lastDayOfMonth = new Date(nextMonthFirstDay - 1);
    // Return the date part of the last day
    return lastDayOfMonth.getDate();
  }

  const getTreeChartData = async () => {
    setLoading(true);
    let startDate = "";
    let endDate = "";
    if (financialYear !== "") {
      if (days === "all") {
        const dates = financialYear.split("-");

        startDate = `${dates[0]}-04-01`;
        endDate = `${dates[1]}-03-31`;
      } else if (days >= "01" && days <= "03") {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[1]}-${days}-01`;
        endDate = `${dates[1]}-${days}-${endMonthLastDay}`;
      } else {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[0]}-${days}-01`;
        endDate = `${dates[0]}-${days}-${endMonthLastDay}`;
      }

      setDateRange([startDate, endDate]);

      const newParams = createSearchParams({
        to: startDate,
        from: endDate,
        companyId: activeCompany?._id,
        companyName: activeCompany?.tallyCompanyName,
      }).toString();

      // let data = await APIService.doGet(
      //   `/admin/tally-barge-wise-list?${newParams}`
      // );
      // let arr = data?.data?.data?.filter((data, index) => {
      //   return data;
      // });
      // setTreeData(arr);
      dispatch(getDashboardBargeTallyWise(newParams))
        .then((res) => {
          console.log("Barge Wise", res);
          if (res.status === 200) {
            setTreeData(res.data);
            setLoading(false);
          } else {
            setTreeData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setTreeData([]);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTreeChartData();
  }, [days, financialYear, activeCompany]);

  const handleBargeDetails = (event, data) => {
    event.preventDefault();
    const searchParams = createSearchParams({
      bargeId: data?.bargeId,
      bargName: data?._id,
      to: dateRange[0],
      from: dateRange[1],
      companyId: activeCompany?._id,
      companyName: activeCompany?.tallyCompanyName,
    }).toString();

    const url = `/admin/invoice/invoice-details?${searchParams}`;
    window.open(url, "_blank");
  };

  const getBargePuchaseInvoiceDetails = React.useCallback(
    (event, bargeId, bargeName) => {
      const searchParams = createSearchParams({
        month: props.days,
        bargeId: bargeId,
        bargName: bargeName,
        to: dateRange[0],
        from: dateRange[1],
        companyId: activeCompany?._id,
      }).toString();

      const url = `/admin/invoice/purchase-invoice-details?${searchParams}`;
      window.open(url, "_blank");
    },
    [dateRange]
  );

  return (
    <div>
      {/* <ResponsiveContainer width={"100%"} height={200}>
      
        <Treemap
          width={320}
          height={200}
          data={treeData}
          dataKey="value"
          aspectRatio={4 / 3}
          stroke="#fff"
          fill="#8884d8"
        >
          <XAxis dataKey="name" />
          <YAxis dataKey="value" />
          <Tooltip
            formatter={(value) => `sales: ${value}`}
          />
        </Treemap>
      
      </ResponsiveContainer> */}

      <Index.Box className="tree-analysis-main">
        {loading ? (
          <>
            <Index.Box className="tree-box-loader">
              <Index.CircularProgress color="secondary" size={20} />
            </Index.Box>
          </>
        ) : (
          <>
            <Index.List className="tree-list-analysis">
              {treeData &&
                treeData?.map(
                  (item, index) =>
                    item?._id !== null && (
                      <Index.ListItem className="tree-listitem-analysis">
                        <Index.Box className="box-card-tree-analysis">
                          <Index.Box key={index} className="pd-box-card-tree">
                            <Index.Typography
                              variant="h6"
                              component="h6"
                              className="title-tree-list"
                            >
                              {item?._id}
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="price-tree-list"
                              onClick={(e) => {
                                handleBargeDetails(e, item);
                              }}
                            >
                              <strong>S :</strong> &nbsp;
                              <span>₹</span>{" "}
                              {item?.amount?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="price-tree-list"
                              onClick={(e) => {
                                getBargePuchaseInvoiceDetails(
                                  e,
                                  item?.bargeId,
                                  item?._id
                                );
                              }}
                            >
                              <strong>P :</strong> &nbsp;
                              <span>₹</span>{" "}
                              {item?.totalPurchaseData
                                ? (item?.totalPurchaseData?.Total_cost > 0
                                    ? item?.totalPurchaseData?.Total_cost
                                    : item?.totalPurchaseData?.Total_cost * -1
                                  )?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    )
                )}
            </Index.List>
          </>
        )}
      </Index.Box>
    </div>
  );
};

export default TreeMap;
