import Index from "../../../../Index";
import React, { useState, useEffect } from "react";
import APIService from "../../../../../redux/APIService";
import { createSearchParams } from "react-router-dom";
import { getDashboardCategoryTallyWise } from "../../../../../redux/admin/action";
const CategoryTreeMap = (props) => {
  const dispatch = Index.useDispatch();
  const { financialYear, days } = props;
  const { categoryWiseDashboard, activeCompany } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const [treeData, setTreeData] = useState(categoryWiseDashboard || []);
  const [dateRange, setDateRange] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function getLastDayOfMonth(year, month) {
    // Create a Date object with the next month's first day
    var nextMonthFirstDay = new Date(year, month, 1);
    // Subtract one day from the next month's first day
    var lastDayOfMonth = new Date(nextMonthFirstDay - 1);
    // Return the date part of the last day
    return lastDayOfMonth.getDate();
  }

  const getTreeChartData = async () => {
    setLoading(true);
    let startDate = "";
    let endDate = "";
    if (financialYear !== "") {
      if (days === "all") {
        const dates = financialYear.split("-");

        startDate = `${dates[0]}-04-01`;
        endDate = `${dates[1]}-03-31`;
      } else if (days >= "01" && days <= "03") {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[1]}-${days}-01`;
        endDate = `${dates[1]}-${days}-${endMonthLastDay}`;
      } else {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[0]}-${days}-01`;
        endDate = `${dates[0]}-${days}-${endMonthLastDay}`;
      }

      setDateRange([startDate, endDate]);

      const newParams = createSearchParams({
        to: startDate,
        from: endDate,
        companyId: activeCompany?._id,
        companyName: activeCompany?.tallyCompanyName,
      }).toString();

      // let data = await APIService.doGet(`/admin/category-wise/?${newParams}`);
      // let arr = data?.data?.data?.filter((data, index) => {
      //   return data;
      // });
      // setTreeData(arr);

      dispatch(getDashboardCategoryTallyWise(newParams))
        .then((res) => {
          if (res.status === 200) {
            setTreeData(res.data);
            setLoading(false);
          } else {
            setTreeData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setTreeData([]);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTreeChartData();
  }, [days, financialYear, activeCompany]);

  const handleCategoryDetails = (event, data) => {
    event.preventDefault();
    const searchParams = createSearchParams({
      ledger: data?._ledger,
      categoryName: data?.ledger,
      to: dateRange[0],
      from: dateRange[1],
      // companyId: activeCompany?._id,
      companyName: activeCompany?.tallyCompanyName,
    }).toString();

    const url = `/admin/category-wise-invoice-details?${searchParams}`;
    window.open(url, "_blank");
  };

  // const getBargePuchaseInvoiceDetails = React.useCallback(
  //     (event, bargeId, bargeName) => {
  //         const searchParams = createSearchParams({
  //             month: props.days,
  //             bargeId: bargeId,
  //             bargName: bargeName,
  //             to: dateRange[0],
  //             from: dateRange[1],
  //         }).toString();

  //         const url = `/admin/invoice/purchase-invoice-details?${searchParams}`;
  //         window.open(url, "_blank");
  //     },
  //     [dateRange]
  // );

  return (
    <Index.Box className="chart-graph-devider cattable-devider">
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell>No.</Index.TableCell>
                <Index.TableCell align="left">Category name</Index.TableCell>
                <Index.TableCell align="left">Details</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={4}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </>
              ) : (
                <>
                  {treeData?.map((items, index) => {
                    return (
                      <Index.TableRow
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCategoryDetails(e, items);
                        }}
                        // handleInvoiceDetails(items, "Category Wise");
                        // /admin/category-wise-invoice-details
                      >
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>{items?.ledger}</Index.TableCell>
                        <Index.TableCell>
                          ₹{" "}
                          {items?.totalAmount?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </Index.Box>
  );
};

export default CategoryTreeMap;
